import { HttpClient } from '@angular/common/http'
import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { HTTP } from '@awesome-cordova-plugins/http/ngx'
import { Capacitor } from '@capacitor/core'
import { environment } from 'src/environments/environment'
import { Resource } from '../interfaces/resource'
import { Widget } from '../interfaces/widget'
import { DelphireApiService } from '../services/delphire-api.service'
import { DelphireLinkService } from '../services/delphire-link.service'
import { FirebaseService } from '../services/firebase.service'
import { LocalStorageService } from '../services/local-storage-service.service'


interface RecommendationItem {
  [key: string]: any
}

interface ResourceParams {
  [key: string]: any
}

interface Nudge {
  match_score?: number
  message?: string
  title?: string
}

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.sass']
})
export class RecommendationsComponent implements OnInit {
  @Input()
  widget: Widget | undefined
  verbose: boolean = environment.production ? false : true
  recommendations: RecommendationItem[] = []
  nudges: Nudge = {}
  today = new Date()

  constructor(
    private http: HttpClient,

    private nativeHTTP: HTTP,
    private linkService: DelphireLinkService,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private fbS: FirebaseService,
    private delphireApi: DelphireApiService
  ) {}

  ngOnInit(): void {
    const { user } = this.localStorage.get('delphireUser')
    const tenantId = this.localStorage.get('currentSpace').id
    const delphireApi = this.delphireApi
    this.recommendations = [
      {
        name: '',
        description: 'No recommendations available',
      }
      // {
      //   name: 'NAME 2',
      //   description: 'DESCRIPTION 2'
      // }
    ]

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    try {
      // let _url = 'https://syneos.discourseanalytics.net/reports/data/custom?QueryName=syneos_user_idos&format=json&project=profiles&Download_Key=%7B39CC58AE-E0D6-4318-872E-27DA62C4CE5D%7D'
      // let _userId = '-M8QMCFE37ZY8tjs6glG' // jon
      let _url = self.widget?.parameters?.queryBaseUrl
      let _userId = user.id
      if (Capacitor.isNativePlatform()) {
        this.nativeHTTP
          .get(_url + '&client_user_id=' + _userId, null, null)
          .then(function (response: any) {
            console.log(response)
            const data = JSON.parse(response.data)

            self.nudges = data.arrRecords[0].nudges[0]

            let _recommendations = data.arrRecords[0].recommendations
            let _resourceIdArray: string[] = []

            _recommendations.forEach((element: any) => {
              _resourceIdArray.push(element.product_id)
            })
            console.log('_resourceIdArray', _resourceIdArray)
            if(_resourceIdArray.length > 0) {
              self.recommendations = []
            } 
            _resourceIdArray.forEach((element: any) => {
              delphireApi.postRequestPrisma('/iam/courses',{tenantId,legacyId: element}).then((response) => {
                response.subscribe((response) => {
                  const course = {
                    name:response.body.title,
                    description:response.body.metadata.description,
                    type: 'course'
                  }
                  self.recommendations.push(course)
              }
              )
            }
            ) 
          })
          }
          )
      } else {
        this.http
          .get<any>(_url + '&client_user_id=' + _userId)
          // .get<any>(_url + '&client_user_id=' + '-M8GsFOGx_-2ot4-jLZA')
          .subscribe(function (response: any) {
            console.log(response)
            const data = response
            let _recommendations = []
            if (data.arrRecords.length === 0) {
              self.nudges = {}
            } else {
              self.nudges = data.arrRecords[0].nudges[0]
              _recommendations = data.arrRecords[0].recommendations
              self.recommendations = []
            }
            let _resourceIdArray: string[] = []

            _recommendations.forEach((element: any) => {
              _resourceIdArray.push(element.product_id)
            })
            console.log('_resourceIdArray', _resourceIdArray)
            _resourceIdArray.forEach((element: any) => {
              delphireApi.postRequestPrisma('/iam/courses',{tenantId,legacyId: element}).then((response) => {
                response.subscribe((response) => {
                  const course = {
                    name:response.body.title,
                    description:response.body.metadata.description,
                    type: 'course'
                  }
                  self.recommendations.push(course)
              }
              )
            }
            ) 
          })
          }
          )
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  handleLink(resource: any): void {
    if (this.verbose) {
      console.log('%c[ handleLink ]', 'background: red', resource)
    }
    let linkParams = {
      route: this.route,
      type: resource.type,
      resource: {} as Resource
    }

    const resourceTypes = [
      'audio',
      'document',
      'video',
      'package',
      'resource',
      'image',
      'course'
    ]
    const itemType = resource.type.toLowerCase()

    if (resourceTypes.includes(itemType)) {
      linkParams.resource.id = resource.id
      linkParams.resource.name = resource.name
      linkParams.resource.type = resource.type
    }

    //! NEED TO MAKE HANDLELINK WORK FOR PACKAGES ONLY PROBABLY
    //! BUT GETTING ERROR ON URL
    //! Cannot GET /tenants/-Linmghl3lHAxP3flsNj/resources/2637fc8c-d240-46bd-a7ec-719aa34f56da
    // else {
    //   linkParams.id = resource.id
    // }

    if (this.verbose) {
      console.log('%c[ linkParams ]', 'background: lime', linkParams)
    }

    this.linkService.handleLink(linkParams)
  }

  // $scope.handleOpenLink = (resourceId) ->
  //   tenantId = $scope.currentTenant.id
  //   if true # isInCurrentBusinessUnit($scope.currentTenant)
  //     $resource = ResourceService.getFirebaseObject resourceId
  //     # $resource = ResourceService.getFirebaseObject '06e21a92-77c0-49cd-99ca-859b94e0702a'
  //     $resource.$loaded () ->
  //       console.log '----handleOpenLink---', $resource
  //       if !$resource.id
  //         showAlert('Resource doesn\'t exist in the current tenant!')
  //         return

  //       resourcePromise = LinkService.handleResource($resource)
  //       if $resource.type is 'document'
  //         console.log 'document'
  //         $resource.documentLastTime = learning.documentLastTime
  //       else if $resource.type is 'video'
  //         console.log 'video'
  //         $resource.mediaLastTime = learning.mediaLastTime
  //       else
  //         args =
  //           type: 'Resource'
  //           parameters:
  //             resourceId: learning.resourceId
  //             layoutId: $rootScope.layouts[0].id
  //         resourcePromise = LinkService.handleLink(args)

  //       resourcePromise.then () ->
  //         console.log 'resource opened'
  //       .catch (error) ->
  //         $scope.showAlert(error)
}
