import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { getApp } from '@firebase/app'
import { getDatabase, onValue, ref } from '@firebase/database'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { DelphireUtilities } from '../../utilities/delphire-utilities'
import { DelphireUser } from '../interfaces/delphire-user'
import { Layout } from '../interfaces/layout'
import { Space } from '../interfaces/space'
import { FirebaseService } from '../services/firebase.service'
import { LocalStorageService } from '../services/local-storage-service.service'
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.sass']
})
export class LayoutComponent implements OnInit, OnDestroy {
  layouts: Layout[] = []

  space: Space
  currentScreenSize: string | undefined
  gridCols: number = 0
  destroyed = new Subject<void>()
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [Breakpoints.Large, 'Large'],
    [Breakpoints.XLarge, 'XLarge']
  ])

  selectLayout(layout: Layout): void {
    this.localStorage.set('currentLayout', layout)
    if (this.space) {
      const spaceUrl: string = DelphireUtilities.normalizeName(this.space.name)
      this.router.navigate(['spaces/' + spaceUrl + '/layouts/', layout.id])
    }
  }
  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    private fbS: FirebaseService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {

    const user:DelphireUser = this.localStorage.get('delphireUser')

    this.localStorage.getItem('currentSpace').subscribe((space) => {
      this.space = space
      const layoutIds:String[] = []
      const userLayoutIds:String[] = []

      this.space.layoutEdges.forEach((l)=>{
          const layoutId = l['layoutId']
          layoutIds.push(layoutId)
        })

      if(user.layouts)
          user.layouts.forEach((l)=>{
          const layoutId = l.id
          if(layoutId)
            userLayoutIds.push(layoutId)
        })

      const layoutIdsToUse = layoutIds.filter((l)=>userLayoutIds.indexOf(l) > -1)

      this.space.layoutIds = layoutIdsToUse
      const { databaseUrl } = space
      const app = getApp()
      const db = getDatabase(app, databaseUrl)
      onValue(
        ref(db, environment.firebasePaths.space + 'layouts'),
        (snapShot) => {
          if (snapShot.exists()) {
            let layouts: Layout[] = []
            snapShot.forEach((child) => {
              if(layoutIdsToUse.indexOf(child.val().id) > -1)
              layouts.push(child.val())
            })
            this.layouts = layouts;
            if(this.layouts?.length === 1){
              this.selectLayout(this.layouts[0])
            }
          }
        }
      )
    })

    this.localStorage.changes$.subscribe((change: any) => {
      if (change.type === 'set' && change.key === 'currentSpace') {
        this.space = change.value
        const { databaseUrl } = change.value
        const app = getApp()
        const db = getDatabase(app, databaseUrl)
        const layoutIds:string[] = change.value.layoutIds
        onValue(
          ref(db, environment.firebasePaths.space + 'layouts'),
          (snapShot) => {
            if (snapShot.exists()) {
              let layouts: Layout[] = []
              snapShot.forEach((child) => {
                if(layoutIds.indexOf(child.val().id) > -1)
                layouts.push(child.val())
              })
              this.layouts = layouts
            }
          }
        )
      }
    })
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = this.displayNameMap.get(query) ?? 'Unknown'
            switch (this.currentScreenSize) {
              case 'XSmall':
                this.gridCols = 2
                break
              case 'Small':
                this.gridCols = 3
                break
              case 'Medium':
                this.gridCols = 4
                break
              case 'Large':
                this.gridCols = 4
                break
              case 'XLarge':
                this.gridCols = 4
                break
              default:
                break
            }
          }
        }
      })
  }
  ngOnDestroy() {
    this.destroyed.next()
    this.destroyed.complete()
  }
}
