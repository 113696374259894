<div class="recommendations" fxFlex fxLayout="column">
  <div class="title">{{ widget?.parameters?.title }}</div>
  <div class="nudge">
    <div *ngIf="nudges.title" class="nudge-title">{{ nudges.title }}</div>
    <div *ngIf="nudges.title" class="nudge-date">{{ today | date:'MM/dd/yyyy' }}</div>
    <div *ngIf="nudges.message" class="nudge-message">{{ nudges.message }}</div>
  </div>
  <div class="content">
    <!-- <div class="content-title">{{ widget?.parameters?.recommendationsText }}</div> -->
    <div *ngFor="let recommendation of recommendations" (click)="handleLink(recommendation)" class="recommendation">
      <div class="recommendation-name">{{ recommendation.name }}</div>
      <div class="recommendation-description">{{ recommendation.description }}</div>
    </div>
  </div>
</div>

<!-- <div class="recommendations">
  <div class="title">{{parameters.title}}</div>
  <div class="nudge">
    <div class="nudge-title">{{parameters.nudgesTitle}}</div>
    <div class="nudge-date">{{ today | date:'MM/dd/yyyy' }}</div>
    <div class="nudge-message">{{nudge.message}}</div>
  </div>
  <div class="content">
    <div class="content-title">{{parameters.recommendationsText}}</div>
    <div ng-repeat="recommendation in recommendations" ng-click="handleOpenLink(recommendation.product_id)" class="recommendation">
      <div class="recommendation-name">{{resources[recommendation.product_id].name}}</div>
      <div class="recommendation-description">{{resources[recommendation.product_id].description}}</div>
    </div>
  </div>
</div> -->