import { Location } from '@angular/common'
import { HostListener, Injectable, NgZone } from '@angular/core'
import { doc, getDoc, getFirestore } from '@angular/fire/firestore'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx'
import { Http, HttpDownloadFileResult } from '@capacitor-community/http'
import { Browser } from '@capacitor/browser'
import { Capacitor } from '@capacitor/core'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { File } from '@ionic-native/file/ngx'
import { Zip } from '@ionic-native/zip/ngx'
import _ from 'lodash'
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal'
import { SpinnerVisibilityService } from 'ng-http-loader'
import { CookieOptions, CookieService } from 'ngx-cookie'
import { environment } from '../../environments/environment'
import { DelphireUtilities } from '../../utilities/delphire-utilities'
import { DelphireUser } from '../interfaces/delphire-user'
import { ExternalLink } from '../interfaces/external-link'
import { Resource } from '../interfaces/resource'
import { Space } from '../interfaces/space'
import { ConfirmationDialogService } from '../services/confirmation-dialog.service'
import { TrackableDocumentModalComponent } from '../trackable-document-modal/trackable-document-modal.component'
import { TrackableMultiMediaModalComponent } from '../trackable-multimedia-modal/trackable-multimedia-modal.component'
import { DelphireApiService } from './delphire-api.service'
import { DelphireBridgeService } from './delphire-bridge.service'
import { DelphireFileService } from './delphire-file.service'
import { LocalStorageService } from './local-storage-service.service'
import { PdfTrackingService } from './pdf-tracking.service'
import { AnalyticsService } from './tracking/analytics.service'
import { ProgressService } from './tracking/progress.service'
import { WindowRef } from './window-ref.service'

@Injectable({
  providedIn: 'root'
})
export class DelphireLinkService {
  modalRef!: MdbModalRef<TrackableDocumentModalComponent>

  authToken: string | null = null

  PSPDFKit: any

  pdf!: Resource

  bridgeRef!: Window

  @HostListener('window:message', ['$event']) onPostMessage(event: any) {
    // here your code
    console.log('event', event)
  }
  private getApiToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.auth.getAccessTokenSilently().subscribe((token) => {
        resolve(token)
      })
    })
  }
  async getScorm(resource: Resource) {
    // console.log('%c[ resource ]', 'color: deeppink', resource)
    const space: Space = this.localStorage.get('currentSpace')
    const user: DelphireUser = this.localStorage.get('delphireUser')
    return new Promise((resolve, reject) => {
      // if (
      //   window.location.hostname === 'localhost' &&
      //   !Capacitor.isNativePlatform()
      // ) {
      //   this.getApiToken().then((token) => {
      //     const url: string =
      //       environment.scormEngine +
      //       `/tenants/${space.id}/packages/${resource.id}?jwt=${token}`
      //     return resolve({ url: url, scorm: false })
      //   })
      // }
      this.delphireAPI
        .postScormRequest('launchResource', {
          tenantId: space.fbId,
          resourceId: resource.id
        })
        .then((response) => {
          response.subscribe(async (info) => {
            const { url, isRusticiCourse } = info.body.result
            console.log('RESULTS', info.body.result)
            if (url) {
              resolve({ url: url, scorm: isRusticiCourse })
            } else {
              if (Capacitor.isNativePlatform()) {
                const original = await this.delphireAPI.postRequest(
                  'resources/getDownloadUrl',
                  {
                    id: resource.id,
                    sourceKey: 'original'
                  }
                )
                original.subscribe(async (response) => {
                  const { body } = response
                  const { result } = body
                  const { url } = result
                  const unzip = await this.downloadFile(url, resource)
                  if (unzip === 0) {
                    const path = resource?.id || ''
                    const fileURL = Filesystem.getUri({
                      path: path,
                      directory: Directory.Documents
                    })
                    resolve({ url: fileURL, scorm: false })
                  }
                })
              } else {
                resolve({ url: url, scorm: isRusticiCourse })
              }
            }
          })
        })
    })
  }

  async downloadFile(url: string, resource: any) {
    this.spinner.show()
    const resourceId = resource.id || ''
    const fileName = resource.sources.decompressed.original_path.replaceAll(
      '/',
      ''
    )

    // console.log('FILE NAME', fileName)

    try {
      const directoryCheck = await this.file.checkDir(
        this.file.documentsDirectory,
        'modules/uncompressed/' + resourceId
      )

      if (directoryCheck) {
        let modulePath =
          this.file.documentsDirectory +
          'modules/uncompressed/' +
          resourceId +
          '/index.html'
        modulePath = Capacitor.convertFileSrc(modulePath)
        this.localStorage.set('currentModulePath', modulePath)
        this.spinner.hide()
        return 0
      }
    } catch (error) {
      // console.log('FILE ERROR', error)
    }

    try {
      const initialModulesCheck = await this.file.checkDir(
        this.file.documentsDirectory,
        'modules'
      )
      console.log('initialModulesCheck', initialModulesCheck)
      if (!initialModulesCheck) {
        await Filesystem.mkdir({
          path: 'modules',
          directory: Directory.Documents
        })
      }
    } catch (error) {
      console.log('FILE ERROR', error)
    }

    const options = {
      url: url,
      filePath: 'modules/' + resourceId + '.zip',
      fileDirectory: Directory.Documents
    }

    // Writes to local filesystem
    const response: HttpDownloadFileResult = await Http.downloadFile(options)

    // Then read the file
    if (response.path) {
      const unzip = await this.zip
        .unzip(
          this.file.documentsDirectory + 'modules/' + resourceId + '.zip',
          this.file.documentsDirectory + 'modules/uncompressed/' + resourceId,
          (progress: any) =>
            console.log(
              'Unzipping, ' +
                Math.round((progress.loaded / progress.total) * 100) +
                '%'
            )
        )
        .then((val) => {
          let modulePath =
            this.file.documentsDirectory +
            'modules/uncompressed/' +
            resourceId +
            '/index.html'
          modulePath = Capacitor.convertFileSrc(modulePath)
          this.localStorage.set('currentModulePath', modulePath)
          return val
        })
        .catch((error) => {
          console.log('errorZip', error)
        })
      this.spinner.hide()
      return unzip
    } else {
      return -1
    }
  }
  setCookies(resource: Resource): Promise<any> {
    return new Promise((resolve, reject) => {
      if (resource?.id) {
        this.delphireAPI
          .postRequest('resources/getSiteInfo', {
            id: resource.id,
            sourceKey: 'decompressed'
          })
          .then((response) => {
            response.subscribe((info) => {
              const { url, cookies, cookieOptions } = info.body.result
              const options: CookieOptions = cookieOptions
              const date: Date = new Date(cookieOptions.expires)
              options.expires = date
              options.sameSite = 'lax'
              options.secure = true
              let d = cookieOptions.domain.split('.')
              d.shift()
              d.shift()
              d.unshift('')
              d = d.join('.')
              options.domain = d
              for (const key in cookies) {
                const value = cookies[key]
                console.log('options', options)
                //document.cookie = `${key}=${value};domain=${cookieOptions.domain};expires=${cookieOptions.expires};path=${cookieOptions.path}`
                this.cookie.put(key, value, options)
              }
              resolve(url)
            })
          })
      }
    })
  }

  // ------------------------------------------------------------------------

  selfCompleteSimpleItem(
    resource: any,
    resourceId: string,
    newStatus: boolean,
    reason: string,
    roadmapId: string
  ) {
    resource.selfCompletedTimestamp = Date.now()

    let payload = {
      roadmapId: roadmapId,
      itemId: resourceId,
      selfCompleted: newStatus,
      selfCompletedReason: newStatus ? reason : '',
      ts: Date.now(),
      backup: {
        selfCompletedReason: '',
        ts: ''
      }
    }

    if (newStatus) {
      payload.backup = {
        selfCompletedReason:
          resource.backup && resource.backup.selfCompletedReason
            ? resource.backup.selfCompletedReason
            : null,
        ts: resource.backup && resource.backup.ts ? resource.backup.ts : null
      }
    } else {
      payload.backup = {
        selfCompletedReason: reason,
        ts: resource.selfCompletedTimestamp
      }
      resource.backup = payload.backup
    }

    let trackingParams = {
      namespace: 'SelfCompletionProgress',
      type: 'SELF_COMPLETION',
      id: roadmapId,
      originalKey: resourceId,
      payload: payload
    }

    this.trackingServiceProgress.addEvent(trackingParams, 'Portal')

    return trackingParams
  }

  // ------------------------------------------------------------------------

  // if doNotSwitch === true that means we're just changing the reason, not swapping the status
  markSelfComplete(resource: any, reason: string, routeParams: any): any {
    let isReset = reason === 'self-complete-reset-progress'
    let doNotSwitch = !isReset && resource.selfCompleted

    // console.log('%c[ markSelfComplete(resource) ]', 'background-color: lime', resource)
    // console.log('%c[ resource.selfCompleted ]', 'background-color: lime', resource.selfCompleted)
    // console.log('%c[ resource.selfCompletedReason ]', 'background-color: lime', resource.selfCompletedReason)
    // console.log('%c[ reason ]', 'background-color: lime', reason)
    // console.log('%c[ isReset ]', 'background-color: lime', isReset)
    // console.log('%c[ doNotSwitch ]', 'background-color: lime', doNotSwitch)
    // return

    const roadmapId = String(routeParams.get('roadmapId'))
    // console.log('%c[ routeParams ]', 'color: red', routeParams)
    // console.log('%c[ roadmapId ]', 'color: red', roadmapId)

    let wasAlreadySelfCompleted = resource.selfCompleted
    let newStatus = doNotSwitch
      ? resource.selfCompleted
      : !resource.selfCompleted
    let payload, payload2
    let type = resource.type

    let returnValue

    //! ALL THE GOOD STUFF
    switch (type.toLowerCase()) {
      // ------------------------------------------------------------------------
      //! THESE ARE SAVED TO SelfCompletionProgress NODE IN USERDATA ONLY
      // ------------------------------------------------------------------------

      case 'none':
        returnValue = this.selfCompleteSimpleItem(
          resource,
          resource.id,
          newStatus,
          reason,
          roadmapId
        )
        break

      case 'course':
        returnValue = this.selfCompleteSimpleItem(
          resource,
          resource.parameters.courseId,
          newStatus,
          reason,
          roadmapId
        )
        break

      case 'library':
        returnValue = this.selfCompleteSimpleItem(
          resource,
          resource.parameters.libraryId,
          newStatus,
          reason,
          roadmapId
        )
        break

      case 'external link':
      case 'externallink':
        returnValue = this.selfCompleteSimpleItem(
          resource,
          resource.parameters.externalLinkId,
          newStatus,
          reason,
          roadmapId
        )
        break

      // ------------------------------------------------------------------------
      //! FROM HERE DOWN ARE SAVED IN THEIR OWN Progress NODES (MediaProgress etc)
      // ------------------------------------------------------------------------

      case 'quiz':
        let payload4 = {
          answered: '0%',
          attemptNum: 1,
          correct: false,
          possibleAnswers: {
            0: 'A',
            1: 'B'
          },
          question: 'N/A',
          question_id: 'N/A',
          selfCompleted: newStatus,
          selfCompletedReason: newStatus ? reason : ''
        }

        let trackingParams4 = {
          namespace: 'QuizProgress',
          type: 'QUIZ_COMPLETE',
          id: resource.quiz.id,
          originalKey: resource.quiz.id,
          payload: payload4
        }

        this.trackingServiceProgress.addEvent(trackingParams4, 'Portal')

        returnValue = trackingParams4
        break

      // ------------------------------------------------------------------------

      case 'resource':
        switch (resource.resource.type.toLowerCase()) {
          // ---------------------------------

          case 'document':
            // this is where we switch from YES to NO
            if (newStatus === false) {
              resource.documentPercentage = resource.savedPercentage
                ? resource.savedPercentage
                : 0
              payload = {
                currentPage: resource.savedCurrentPage,
                precentage: resource.savedPercentage,
                completed: resource.savedPercentage >= 90,
                selfCompleted: newStatus,
                selfCompletedReason: '',
                selfCompletedDate: ''
              }

              // this is where we switch from NO to YES (or first say YES)
            } else {
              resource.savedCurrentPage = resource.documentLastTime

              if (!wasAlreadySelfCompleted) {
                resource.savedPercentage = resource.documentPercentage
              }

              payload = {
                currentPage: resource.documentLastTime,
                precentage: 100,
                completed: true,
                selfCompleted: newStatus,
                selfCompletedReason: reason,
                selfCompletedDate: Date.now(),
                savedCurrentPage: resource.savedCurrentPage
                  ? resource.savedCurrentPage
                  : 1,
                savedPercentage: resource.savedPercentage
                  ? resource.savedPercentage
                  : 0
              }
            }

            // console.log('%c[ selfCompleted set to ]', 'background-color: red', newStatus)
            // console.log('%c[ payload ]', 'background-color: yellow', payload)

            let trackingParams = {
              namespace: 'DocumentProgress',
              type: 'DOCUMENT_PROGRESS',
              id: resource.resource.id,
              originalKey: resource.resource.id,
              payload: payload
            }

            this.trackingServiceProgress.addEvent(trackingParams, 'Portal')

            returnValue = trackingParams
            break // document

          // ---------------------------------

          case 'audio':
          case 'video':
            resource.completed = newStatus
            resource.mediaCompleted = newStatus
            if (newStatus) {
              resource.savedCurrentTime = resource.mediaLastTime
              if (!doNotSwitch)
                resource.savedPercentage = resource.mediaPercentage
              payload2 = {
                currentTime: resource.mediaLastTime,
                percentage: 100,
                completed: true,
                selfCompleted: newStatus,
                selfCompletedReason: reason,
                selfCompletedDate: Date.now(),
                savedCurrentTime: resource.savedCurrentTime,
                savedPercentage: resource.savedPercentage
              }
            } else {
              payload2 = {
                currentTime: resource.savedCurrentTime,
                percentage: resource.savedPercentage
                  ? resource.savedPercentage
                  : 0,
                completed: resource.savedPercentage > 95,
                selfCompleted: newStatus,
                selfCompletedReason: '',
                selfCompletedDate: ''
              }
            }

            let trackingParams2 = {
              namespace: 'MediaProgress',
              type: 'MEDIA_CURRENT_TIME',
              id: resource.resource.id,
              originalKey: resource.resource.id,
              payload: payload2
            }

            this.trackingServiceProgress.addEvent(trackingParams2, 'Portal')

            returnValue = trackingParams2

            break // audio/video

          // ---------------------------------

          case 'package':
            let payload3 = {
              moduleProgress: 0,
              moduleSlide: 0,
              moduleTotalSlides: 0,
              selfCompleted: newStatus,
              selfCompletedReason: newStatus ? reason : '',
              selfCompletedDate: newStatus ? Date.now() : ''
            }

            let trackingParams3 = {
              namespace: 'ModuleProgress',
              type: 'SLIDE_COMPLETE',
              id: resource.resource.id,
              originalKey: resource.resource.id,
              payload: payload3
            }

            this.trackingServiceProgress.addEvent(trackingParams3, 'Portal')

            returnValue = trackingParams3

            break
        }

        break // resource

      // ------------------------------------------------------------------------
    }

    return returnValue
  }

  // ------------------------------------------------------------------------

  trackPages(event: any): void {
    const { page } = event
    try {
      this.ngZone.run(() => {
        this.pdfTrackingService.trackPages(page)
      })
    } catch (error) {
      console.log('INITIAL PAGE EVENT')
    }
  }

  initializePDFTracking(): void {
    if (this.pdf?.payload?.currentPage) {
      this.PSPDFKit.setPage(this.pdf?.payload?.currentPage - 1)
    }

    this.PSPDFKit.getPageCount((count: number) => {
      this.ngZone.run(() => {
        this.pdfTrackingService.setPageCount(count)
      })
    })

    this.pdfTrackingService.setResource(this.pdf)
    this.spinner.hide()
  }

  receiveMessage(evt: any): void {
    console.log('hit')
    console.log('evt', evt.data)
  }

  async handleLink(params: Params): Promise<void> {
    const { id, type, resource, route, parameters } = params
    const user: DelphireUser = this.localStorage.get('delphireUser')

    console.log(
      '%c in delphire-link.service handleLink() function',
      'color:yellow'
    )
    console.log(params)

    switch (type) {
      case 'document':
        const trackingParams = {
          service: 'LinkService',
          action: 'open',
          target: {
            type: resource.type.toLocaleLowerCase(),
            id: resource?.id,
            description: resource?.name
          }
        }
        this.trackingService.addEvent(trackingParams, 'Portal')

        //OPEN Native PDF Kit on IOS
        if (Capacitor.isNativePlatform()) {
          this.spinner.show()
          this.pdf = resource
          const pdfPath: string = await this.fileService.getFile(resource)
          this.PSPDFKit = this.windowRef.nativeWindow.PSPDFKit
          this.PSPDFKit.setLicenseKey(environment.pspdfkit.nativeLicense)
          const willBeginListener = this.PSPDFKit.addEventListener(
            'willBeginDisplayingPageView',
            (event: any) => {
              this.ngZone.run(() => {
                this.trackPages(event)
              })
            }
          )
          const willDismissListener = this.PSPDFKit.addEventListener(
            'willDismiss',
            () => {
              this.ngZone.run(() => {
                this.PSPDFKit.removeEventListener(willBeginListener)
                this.PSPDFKit.removeEventListener(willDismissListener)
              })
            }
          )
          if (pdfPath) {
            this.PSPDFKit.present(pdfPath, {}, () => {
              this.ngZone.run(() => {
                this.initializePDFTracking()
              })
            })
          }
        } else {
          this.modalRef = this.modalService.open(
            TrackableDocumentModalComponent,
            {
              data: { resource: resource },
              modalClass: 'modal-xl modal-document',
              animation: false
            }
          )
          this.location.go(
            window.location.pathname + '/' + type + '/' + resource.id
          )
        }
        break
      case 'video':
      case 'audio':
        if (resource) {
          const trackingParams = {
            service: 'LinkService',
            action: 'open',
            target: {
              type: resource.type.toLocaleLowerCase(),
              id: resource?.id,
              description: resource?.name
            }
          }
          this.trackingService.addEvent(trackingParams, 'Portal')
          this.modalRef = this.modalService.open(
            TrackableMultiMediaModalComponent,
            {
              data: { resource: resource },
              modalClass: 'modal-xl modal-video',
              animation: false
            }
          )
          this.location.go(
            window.location.pathname + '/' + type + '/' + resource.id
          )
        }
        break
      case 'package':
        if (resource) {
          const trackingParams = {
            service: 'LinkService',
            action: 'open',
            target: {
              type: resource.type.toLocaleLowerCase(),
              id: resource?.id,
              description: resource?.name
            }
          }
          this.trackingService.addEvent(trackingParams, 'Portal')
          if (Capacitor.isNativePlatform()) {
            this.getScorm(resource).then((params: any) => {
              const { url, scorm } = params
              if (params.url) {
                const iab = this.iab.create(url, '_blank', {
                  location: 'no',
                  presentationstyle: 'fullscreen',
                  toolbarposition: 'top',
                  allowInlineMediaPlayback: 'yes'
                })
                console.log('iab', iab)
                this.bridgeService.default.bridgeWindow =
                  window['webkit'].messageHandlers['cordova_iab']
                iab.on('message').subscribe((m: any) => {
                  console.log('message', m)
                  const { eventName, data } = m.data
                  console.log('eventName', eventName)
                  if (eventName === 'request-api-data') {
                    this.bridgeService.apiData().then((results) => {
                      console.log('results', results)
                      iab.executeScript({
                        code:
                          'window.postMessage(' +
                          JSON.stringify({
                            eventName: 'delphire-api-data',
                            data: results
                          }) +
                          ')'
                      })
                    })
                  }
                  if (eventName === 'module-progress') {
                    data.namespace = 'ModuleProgress'
                    console.log('module-progress called from bridge', data)
                    this.trackingServiceProgress.addEvent(data)
                  }
                })

                iab.on('loadstop').subscribe(() => {
                  console.log('load stop')
                })
                //Browser.open({ url: url, toolbarColor: '#45527a' })
              } else {
                const space: Space = this.localStorage.get('currentSpace')
                const spaceName = DelphireUtilities.normalizeName(space.name)
                //if (_.isEmpty(route.params.value)) {
                this.router.navigateByUrl(
                  'spaces/' + spaceName + '/module/' + resource.id
                )
              }
            })
          } else {
            this.getScorm(resource).then((params: any) => {
              console.log('PARAMS', params)
              const { url, scorm } = params
              this.localStorage.set('currentModulePath', url)
              const space: Space = this.localStorage.get('currentSpace')
              const spaceName = DelphireUtilities.normalizeName(space.name)
              console.log('URL', url)
              console.log('SCORM', scorm)
              if (url && scorm) {
                window.open(url, '_blank')
              } else {
                this.router.navigateByUrl(
                  'spaces/' + spaceName + '/module/' + resource.id
                )
              }
            })
          }
        }
        break
      case 'externallink':
        if (!resource.parameters) {
          resource.parameters = { externalLinkId: resource.id }
          console.log(
            '%c[ resource.parameters ]',
            'color: aqua',
            resource.parameters
          )
        }

        if (resource) {
          const extType = resource.parameters.externalLinkId
          let id
          // console.log('1st LOG  extType', extType)
          if (extType) {
            id = resource.parameters.externalLinkId
          } else {
            id = resource.link.linkId || resource.link.id
          }
          const trackingParams = {
            service: 'LinkService',
            action: 'open',
            target: {
              type: 'externallink',
              id: id,
              description: resource?.name
            }
          }
          this.trackingService.addEvent(trackingParams, 'Portal')
          if (resource.parameters.externalLinkId) {
            const extLinkId = resource.parameters.externalLinkId
            if (extLinkId) {
              // console.log('2nd LOG  extLinkId', extLinkId)
              const ext = doc(getFirestore(), 'externalLinks', extLinkId)
              const extLink = await getDoc(ext)
              // console.log('3rd LOG extLink', extLink)
              if (extLink.exists()) {
                const extObj: ExternalLink = extLink.data() as ExternalLink
                const { url } = extObj
                // console.log('4th LOG url', url)
                if (Capacitor.isNativePlatform()) {
                  Browser.open({ url: url })
                } else {
                  if(extObj.target === '_self') {
                    this.localStorage.set('currentSitePath', url)
                    const space: Space = this.localStorage.get('currentSpace')
                    const spaceName = DelphireUtilities.normalizeName(space.name)
                    this.router.navigateByUrl(
                      `spaces/${spaceName}/appbridge/${resource.id}?engineTenantName=${space.id}`
                    )
                }else{
                  window.open(url, '_blank')
                }
                }
              }

          } else {
            window.open(resource.link.parameters.url, '_blank')
          }
        }
      }

        break

      case 'course':
        console.log('course', id)
        let tenantId = this.localStorage.get('currentSpace').id
        if (Capacitor.isNativePlatform()) {
          tenantId = this.localStorage.get('currentSpace').id
        }
        const link = await this.delphireAPI.postRequestPrisma(
          '/iam/getLaunchLink',
          {
            tenantId,
            courseId: id,
            learnerId: user.user.id
          }
        )

        link.subscribe(async (res) => {
          console.log('res', res)

          if (res.body.error) {
            const link = await this.delphireAPI.postRequestPrisma(
              '/iam/getLaunchLink',
              {
                tenantId: environment.globalScormTenantId,
                courseId: id,
                learnerId: user.user.id
              }
            )

            link.subscribe(async (res) => {
              console.log('res', res)
              if (res.body.error) {
                const dialogOptions = {
                  title: 'You are not registered for this course',
                  message:
                    'Please contact your administrator to register for this course.',
                  cancelText: 'OK',
                  id: 'quiz',
                  panelClass: 'quizDialog'
                }
    
                this.dialogService.open(dialogOptions)
    
                this.dialogService.confirmed().subscribe((confirmed) => {
                  if (confirmed) {
                    this.dialogService.confirmed()
                    return
                  }
                })
              }else{
                if (Capacitor.isNativePlatform()) {
                  const iab = this.iab.create(res.body, '_blank', {
                    location: 'no',
                    presentationstyle: 'fullscreen',
                    toolbarposition: 'top',
                    allowInlineMediaPlayback: 'yes'
                  })
                } else {
                  window.open(res.body, '_blank')
                }
              }
            })
          } else {
            if (Capacitor.isNativePlatform()) {
              const iab = this.iab.create(res.body, '_blank', {
                location: 'no',
                presentationstyle: 'fullscreen',
                toolbarposition: 'top',
                allowInlineMediaPlayback: 'yes'
              })
            } else {
              window.open(res.body, '_blank')
            }
          }
        })

        break

      default:
        if (_.isEmpty(route.params.value)) {
          const space: Space = this.localStorage.get('currentSpace')
          const spaceName = DelphireUtilities.normalizeName(space.name)
          this.router.navigateByUrl(
            'spaces/' + spaceName + '/' + type + '/' + id.replace(/,/g, '')
          )
        } else {
          console.log('type', type)
          console.log('route', route)
          this.router.navigate([type + '/' + id.replace(/,/g, '')], {
            relativeTo: route
          })
        }
        break
    }
  }
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private delphireAPI: DelphireApiService,
    private modalService: MdbModalService,
    private cookie: CookieService,
    private localStorage: LocalStorageService,
    private windowRef: WindowRef,
    private trackingService: AnalyticsService,
    private trackingServiceProgress: ProgressService,
    private location: Location,
    private spinner: SpinnerVisibilityService,
    private auth: AuthService,
    private pdfTrackingService: PdfTrackingService,
    private zip: Zip,
    private file: File,
    private fileService: DelphireFileService,
    private ngZone: NgZone,
    private iab: InAppBrowser,
    private bridgeService: DelphireBridgeService,
    private dialogService: ConfirmationDialogService
  ) {}
}
