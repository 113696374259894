import { Injectable } from '@angular/core'
import { getApp } from '@angular/fire/app'
import {
  collection,
  collectionGroup,
  collectionSnapshots,
  DocumentData,
  getFirestore,
  query,
  where
} from '@angular/fire/firestore'
import { Observable, of } from 'rxjs'
import { ItemLink } from '../interfaces/item-link'
import { ProgressData } from '../interfaces/progress-data'
import { Task } from '../interfaces/task'
import { LocalStorageService } from './local-storage-service.service'


enum SimpleTypes {
  Library = 'library',
  Roadmap = 'roadmap',
  ExternalLink = 'external link',
  FluencyTool = 'fluency',
  Verbalizer = 'verbalizer',
  Modal = 'modal',
  None = 'none'
}

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  getCompletionStatus(item: Task): string {
    if (this.isSimple(item)) {
      if (item.completed || item.started) {
        return 'complete'
      }
    } else {
      if (item.completed) {
        return 'complete'
      }
      if (item.started) {
        return 'resume'
      }
    }
    return 'start'
  }
  getCompletionPercent(item: Task): number {
    if (item.completed || this.getCompletionStatus(item) === 'complete' || item.selfCompleted) {
      return 100
    }

    switch (item.type) {
      
      case 'Course':
        return item.percentage ? item.percentage : 0
      case 'Resource':
        switch (item.resource?.type) {
          case 'audio':
          case 'video':
            return item.mediaPercentage ? item.mediaPercentage : 0
          case 'document':
            return item.documentPercentage ? item.documentPercentage : 0
          case 'package':
            return item.modulePercentage ? item.modulePercentage : 0
          default:
            return 0
        }

      default:
        return 0
    }
  }
  progressData: any[] = []


  

  updateProgress(item: Task, data: ProgressData[], resourceType: string): Task {
    if (data.length > 0) {
      item.started = true
      if (resourceType === 'quiz') {
        item.completed = true
        return item
      }
      switch (resourceType) {
        case 'audio':
        case 'video':
          data.forEach((doc) => {
            if (doc.type === 'MEDIA_CURRENT_TIME') {
              item.mediaLastTime = doc.payload?.currentTime
              item.mediaPercentage = doc.payload?.percentage || 0
              item.percentage = doc.payload?.percentage || 0

              let wasSelfCompleted = doc.payload?.selfCompleted

              if (doc.payload?.completed === 'false') {
                item.completed = wasSelfCompleted || false
                item.mediaCompleted = false
              } else {
                item.completed = wasSelfCompleted || Boolean(doc.payload?.completed)
                item.mediaCompleted = Boolean(doc.payload?.completed)
              }
              item.duration =
                doc.payload?.duration ||
                Math.round( (item.payload?.mediaLastTime / item.mediaPercentage) * 100 )

              item.selfCompleted = doc.payload?.selfCompleted
              item.selfCompletedReason = doc.payload?.selfCompletedReason
            }
          })
          return item
        case 'quiz':
          // if we need to track progress on a quiz, do it here.
          return item
        case 'document':
          data.forEach((doc) => {
            if (doc.type === 'DOCUMENT_PROGRESS') {
              item.documentLastTime = doc.payload?.currentPage || 0
              item.documentPercentage = doc.payload?.percentage || doc.payload?.precentage || 0
              item.percentage = doc.payload?.percentage || doc.payload?.precentage || 0

              let wasSelfCompleted = doc.payload?.selfCompleted
              item.documentCompleted = Boolean(doc.payload?.completed)
              item.completed = wasSelfCompleted || Boolean(doc.payload?.completed)

              item.selfCompleted = doc.payload?.selfCompleted
              item.selfCompletedReason = doc.payload?.selfCompletedReason

              item.pageCount =
                doc.payload?.pageCount ||
                Math.round( (item.documentLastTime / item.documentPercentage) * 100 )
            }
          })
          return item

        case 'package':
        case 'module':
          let wasSelfCompleted = false
          let selfCompletedReason = ''
          data.forEach((doc) => {
            if(!wasSelfCompleted) {
              wasSelfCompleted = doc.payload?.selfCompleted || false
              selfCompletedReason = doc.payload?.selfCompletedReason || ''
            }
            if (doc.type === 'SLIDE_COMPLETE') {
              if (!item.modulePercentage) {
                item.modulePercentage = 0
              }
              if (doc.payload?.moduleProgress) {
                let newPercentage: number = 0
                if (doc.payload.moduleProgress <= 1) {
                  newPercentage = Math.round(doc.payload.moduleProgress * 100)
                } else {
                  newPercentage = doc.payload.moduleProgress
                }
                item.moduleTotalSlides = doc.payload.moduleTotalSlides || 0
                item.modulePercentage = Math.max(
                  newPercentage,
                  item.modulePercentage
                )
                if (item.modulePercentage > 90) {
                  item.moduleCompleted = true
                  item.completed = true
                } else {
                  item.completed = false
                }
              }
            }
            if (doc.type === 'COURSE_COMPLETE') {
              item.modulePercentage = 100
              item.moduleCompleted = true
              item.completed = true
            }
            item.percentage = item.modulePercentage
          })
          item.selfCompleted = wasSelfCompleted
          item.selfCompletedReason = selfCompletedReason
          return item

        default:
          return item
      }
    } else {
      return item
    }
  }
  isSimple(task: Task): boolean {
    let isSimpleItem: boolean = false
    if (task.type && Object.values(SimpleTypes).includes(task.type.toLowerCase() as SimpleTypes)) {
      isSimpleItem = true
    }
    return isSimpleItem
  }

  getFirestoreProgressData(item: Task): Observable<Task> {
    const { id } = this.localStorage.get('currentSpace')
    const { user } = this.localStorage.get('delphireUser')
    if (!item.started) {
      item.completed = false
    }
    if (this.isSimple(item)) {
      item.isSimple = true
      if (item.started || item.selfCompleted) {
        item.completed = true
      }
      return of(item)
    }
    const resourceId: string | undefined = item.resource?.id || item.quiz?.id
    const resourceType: string | undefined = item.resource?.type?.toLowerCase() || item.quiz?.type?.toLowerCase()
    let progressType: string | null = null
    console.log("resourceType" , resourceType)
    switch (resourceType) {
      case 'audio'   : progressType = 'mediaprogress'   ; break
      case 'video'   : progressType = 'mediaprogress'   ; break
      case 'document': progressType = 'documentprogress'; break
      case 'module'  : progressType = 'moduleprogress'  ; break
      case 'package' : progressType = 'moduleprogress'  ; break
      case 'quiz'    : progressType = 'quizprogress'    ; break

      default:
        console.error(
          '[ RoadmapFullTaskDirective ] (checkProgress) Resource missing subtype',
          item
        )
        break
    }

    const app = getApp()
    const firestore = getFirestore(app)
    const progressCollection = collectionGroup(firestore, 'progress')

    const q = query(
      progressCollection,
      where('userId', '==', user.id),
      where('resourceId', '==', resourceId),
      where('userDataType', '==', progressType)
    )

    const observable: Observable<Task> = new Observable((observer) => {
      try {
        collectionSnapshots(q).subscribe((progDataDocs) => {
          if (resourceType) {
            const progData: ProgressData | DocumentData[] = []
            progDataDocs.forEach((doc) => {
              progData.push(doc.data())
            })
            const data: ProgressData[] = progData
            observer.next(this.updateProgress(item, data, resourceType))
          } else {
            observer.next(item)
          }
        })
      } catch (error) {
        observer.error(error)
      }
    })

    return observable
  }

  getFirestoreAnalyticsData(item: Task): Promise<Task> {
    return new Promise((resolve, reject) => {
      const { id } = this.localStorage.get('currentSpace')
      const { user } = this.localStorage.get('delphireUser')

      if (!item.link) {
        item.link = item as ItemLink
      }
      if (item.link) {
        let left: string = ''
        let right: string | undefined = ''
        const linkId: string | undefined =
          item.link?.parameters?.linkId != null
            ? item.link.parameters.linkId
            : item.link?.parameters?.externalLinkId != null
            ? item.link?.parameters?.externalLinkId
            : item.link.id
        if (linkId) {
          left = 'target.id'
          right = linkId
        } else {
          left = 'target.description'
          right = item.link?.parameters?.url
          item.isOldLink = true
        }
        const app = getApp()
        const firestore = getFirestore(app)
        const userDataCollection = collection(
          firestore,
          'userData/' + id + '/analytics'
        )

        const q = query(
          userDataCollection,
          where('userId', '==', user.id),
          where(left, '==', right)
        )
        collectionSnapshots(q).subscribe((snapshot) => {
          if (snapshot.length > 0) {
            item.started = true
            resolve(item)
          } else {
            item.started = false
            resolve(item)
          }
        })
      }
    })
  }

  constructor(
    private localStorage: LocalStorageService ,// private afs: FireStoreServiceService
  ) {}
}
