import { Component, Inject, Input, OnInit } from '@angular/core'
import { getApp } from '@angular/fire/app'
import {
  collectionGroup,
  getDocs,
  getFirestore,
  query,
  where
} from '@angular/fire/firestore'
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
import { Capacitor } from '@capacitor/core'
import _ from 'lodash'
import { LongPollingService } from 'src/app/services/longpolling.service'
import { environment } from '../../environments/environment'
import { FileStatus } from '../interfaces/file-status'
import { Task } from '../interfaces/task'
import { DelphireApiService } from '../services/delphire-api.service'
import { DelphireLinkService } from '../services/delphire-link.service'
import { FirebaseService } from '../services/firebase.service'
import { IconService } from '../services/icon.service'
import { LocalStorageService } from '../services/local-storage-service.service'
import { ProgressService } from '../services/progress.service'
import { UserPreferencesService } from '../services/user-preference.service'

enum SimpleTypes {
  Library = 'library',
  Course = 'course',
  ExternalLink = 'external link',
  FluencyTool = 'fluency',
  Verbalizer = 'verbalizer',
  Modal = 'modal'
}

export interface DialogData {
  task: Task
  title: string
  instruction: string
  reason?: string | undefined
  submitText: string
}

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.sass']
})
export class TaskComponent implements OnInit {
  @Input()
  task: Task = {
    id: ''
  }

  @Input()
  class: string = ''

  download: FileStatus = {
    active: false
  }

  data = {} as DialogData

  verbose: boolean = environment.production ? false : true

  ackText = environment.appLanguage.roadmap.acknowledgement

  getType(item: any): any {
    if (item.type == 'Resource') return item.resource?.type
    let type!: any
    if (item.type == 'Course' && item.course) {
      switch (item.course.metadata?.additionalMetadata?.type) {
        case 'application/zip':
          type = 'Module'
          break
        case 'application/pdf':
          type = 'Document'
          break
        case 'audio/mp3':
          type = 'Audio'
          break
        case 'video/mp4':
          type = 'Video'
          break

        default:
          type = item.parameters?.type
          break
      }
    }
    return type
  }

  getTypeIcon(row: any): string {
    let type = row?.type
    if (row?.type?.toLowerCase() === 'course') {
      type = row.course.metadata?.additionalMetadata?.type
    }
    if (type?.toLowerCase() == 'resource') {
      type = this.task?.resource?.type
    }
    return this.iconService.getIcon(type?.toLowerCase())
  }

  // getTypeIcon(type: any, solid?: boolean): string {
  //   if (type.toLowerCase() == 'resource') {
  //     type = this.task?.resource?.type
  //   }

  //   if (!solid) solid = false

  //   return this.iconService.getIcon(type.toLowerCase(), solid)
  // }

  getFavoriteIcon(type: any, solid?: boolean): string {
    if (type.toLowerCase() == 'resource') {
      type = this.task?.resource?.type
    }

    if (!solid) solid = false

    return this.iconService.getIcon(type.toLowerCase(), solid)
  }

  addFavorite(item: Task) {
    // console.log('%c[ add to favs ]', 'color: aqua', item.type, item)

    let favorite = {}

    switch (item.type?.toLocaleLowerCase()) {
      case 'course':
        if (this.verbose) {
          // console.log('%c[ course ]', 'color: yellow')
        }
        favorite = { id: item.course?.id, type: item.type }
        break

      case 'roadmap':
        if (this.verbose) {
          // console.log('%c[ roadmap ]', 'color: yellow')
        }
        favorite = { id: item.roadmap?.id, type: item.type }
        break

      case 'resource':
        if (this.verbose) {
          // console.log('%c[ resource ]', 'color: yellow', item)
        }
        favorite = { id: item.resource?.id, type: item.resource?.type }
        break

      case 'library':
        if (this.verbose) {
          // console.log('%c[ library ]', 'color: yellow')
        }
        favorite = { id: item.library?.id, type: item.type }
        break

      case 'quiz':
        if (this.verbose) {
          // console.log('%c[ quiz ]', 'color: yellow')
        }
        favorite = { id: item.quiz?.id, type: item.type }
        break

      case 'external link':
        if (this.verbose) {
          // console.log('%c[ external link ]', 'color: yellow')
        }
        const linkId: string | undefined =
          item.link?.id != null
            ? item.link?.id
            : item.parameters?.externalLinkId
        favorite = { id: linkId, type: item.type }
        break

      default:
        break
    }

    this.userPreferences.setFavorites(favorite)
    item.isFavorite = !item.isFavorite
  }

  progressCopy1(task: Task): string {
    return 'Launch'
  }

  progressCopy2(task: Task): string {
    return 'Resume'
  }

  progressCopy3(task: Task): string {
    return 'Complete/Relaunch'
  }

  progressCopy4(task: Task): string {
    if (this.taskAcknowledgement(task)) {
      return this.ackText
    }

    let wording = ''
    if (this.task.selfCompleted) {
      wording = 'Marked Complete'
    } else {
      wording = 'Mark As Complete'
    }
    return wording
  }

  // ---------------------------------

  usingAcknowledgements(): boolean {
    return (
      this.ackText !== undefined && this.ackText !== null && this.ackText !== ''
    )
  }

  taskAcknowledgement(task: Task): boolean {
    let usingAck: boolean = this.usingAcknowledgements()
    let taskHasSCNode: boolean = this.task.selfCompletion !== undefined
    let taskSCEnabled: boolean
    if (this.task.selfCompletion === undefined) {
      taskSCEnabled = false
    } else {
      taskSCEnabled = this.task.selfCompletion.enabled
        ? this.task.selfCompletion.enabled
        : false
    }
    return usingAck && taskHasSCNode && taskSCEnabled
  }

  shouldEnableSelfComplete(task: Task): boolean {
    if (this.usingAcknowledgements()) {
      if (this.task.selfCompleted) {
        return false
      } else {
        return this.getCompletionPercent(this.task) == 100
      }
    }

    if (this.task.selfCompletion && this.task.selfCompletion.enabled) {
      return true
    }

    return false
  }

  // ---------------------------------

  trackProgress(task: Task): boolean {
    if (
     
      task.type === 'Library' ||
      task.type === 'Roadmap' ||
      task.type === 'url'
    ) {
      return false
    } else {
      return true
    }
  }

  trackCourseProgress(task: Task): boolean {
    if (task.type === 'Course') {
      return true
    } else {
      return false
    }
  }

  trackCourseType(task: Task): boolean {
    const type = task?.course?.metadata?.additionalMetadata?.type
    switch (type) {
      case 'application/zip':
        return false
      case 'application/pdf':
        return true
      case 'video/mp4':
        return true
      case 'audio/mp3':
        return true
      default:
        return false
    }
  }

  language: any = environment.appLanguage

  getCompletionStatus(task: Task): string {
    return this.progressService.getCompletionStatus(task)
  }
  getCompletionPercent(task: Task): number {
    return this.progressService.getCompletionPercent(task)
  }

  isFavorite(item: Task): boolean {
    return false
  }
  canFavorite(item: Task): boolean {
    const excluded = ['External Link']
    if (item.type && excluded.indexOf(item.type) > -1) return false
    if (!item) return false
    if (item.link?.id == item.id) return false
    return true
  }

  onOpenClick(task: Task): void {
    if (task.type) {
      const type = task.type.toLocaleLowerCase()
      // console.log(type)
      const anyTask: any = task as any
      let type2 = type

      if (type == 'external link') {
        type2 = 'externalLink'
      }

      // console.log("type2", type2)

      // const taskId = anyTask[type] ? anyTask[type].id : anyTask[type + 'Id']
      const taskId = anyTask[type2]
        ? anyTask[type2].id
        : anyTask[type2 + 'Id']
        ? anyTask[type2 + 'Id']
        : anyTask.learningObjectId
      // console.log("taskId", taskId)
      switch (type) {
        case 'resource':
          if (
            task.resource?.type === 'audio' ||
            task.resource?.type === 'video'
          ) {
            const {
              completed,
              started,
              progress,
              mediaCompleted,
              mediaLastTime,
              mediaPercentage
            } = task
            task.resource = {
              ...task.resource,
              completed,
              started,
              progress,
              mediaCompleted,
              mediaLastTime,
              mediaPercentage
            }
          }
          if (task.resource?.type === 'document') {
            const {
              completed,
              started,
              progress,
              documentCompleted,
              documentPercentage,
              documentLastTime
            } = task
            task.resource = {
              ...task.resource,
              completed,
              started,
              progress,
              payload: {
                ...task.resource.payload,
                documentCompleted,
                documentPercentage,
                documentLastTime,
                currentPage: documentLastTime
              },
              documentCompleted,
              documentPercentage,
              documentLastTime
            }
          }
          this.linkService.handleLink({
            type: task.resource?.type,
            resource: task.resource,
            route: this.route
          })
          break
        case 'external link':
          // console.log(task)
          if (!task.parameters) {
            task.parameters = { externalLinkId: taskId }
          }
          this.linkService.handleLink({
            type: 'externallink',
            resource: task,
            route: this.route
          })
          break

        default:
          this.linkService.handleLink({
            type: type,
            id: taskId,
            route: this.route.parent
          })
          break
      }
    }
  }

  selfComplete(task: Task) {
    //TODO check if feedback is required
    this.openDialog(task)
  }

  // ---------------------------------

  markSelfCompleteAndSave(reason: string) {
    //! LINKSERVICE DOES ALL THE HARD WORK
    const routeParams = this.route.snapshot.paramMap
    let returnValue = this.linkService.markSelfComplete(
      this.task,
      reason,
      routeParams
    )

    //! SET LOCALLY SO PAGE AUTOMATICALLY UPDATES VISUALLY
    this.task.selfCompleted = returnValue.payload.selfCompleted
    this.task.selfCompletedReason = returnValue.payload.selfCompletedReason
  }

  // ---------------------------------

  openDialog(task: Task) {
    let reason: any = null
    if (this.task.selfCompleted) {
      reason = this.task.selfCompletedReason
    }
    const dialogRef = this.dialog.open(SelfCompleteDialog, {
      data: {
        task: this.task,
        title: this.task.selfCompletion?.feedbackPromptTitle,
        instruction: this.task.selfCompletion?.feedbackPromptText,
        reason: reason,
        submitText: this.taskAcknowledgement(task)
          ? environment.appLanguage.roadmap.acknowledgementModalSubmit
          : 'Submit and Mark Complete'
      },
      panelClass: 'roadmap-self-complete-modal'
    })

    dialogRef.afterClosed().subscribe((reason) => {
      if (reason) {
        console.log(`Self Completion Reason: ${reason}`)
        switch (reason) {
          case 'self-complete-reset-progress':
            this.markSelfCompleteAndSave(reason)
            break
          case 'self-complete-cancel-dialog':
            break
          default:
            this.markSelfCompleteAndSave(reason)
        }
      }
    })
  }

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    public userPreferences: UserPreferencesService,
    private linkService: DelphireLinkService,
    private progressService: ProgressService,
    private iconService: IconService,
    private localStorage: LocalStorageService,
    private fbS: FirebaseService,
    private delphireApi:DelphireApiService,
    private longPollService: LongPollingService,
  ) {}

  ngOnInit(): void {
    if (this.verbose) {
      console.log('%c[ this.task ]', 'color: yellow', this.task)
    }
    if (!this.task) {
      return
    }

    // Initial Favorites assignment
    let _id: any
    switch (this.task.type?.toLocaleLowerCase()) {
      case 'course':
        _id = this.task.parameters?.courseId
        break
      case 'roadmap':
        _id = this.task.parameters?.roadmapId
        break
      case 'resource':
        _id = this.task.parameters?.resourceId
        break
      case 'library':
        _id = this.task.parameters?.libraryId
        break
      case 'quiz':
        _id = this.task.parameters?.quizId
        break
      case 'external link':
        if (this.task.parameters?.linkId) {
          _id = this.task.parameters?.linkId
        } else {
          if (this.task.parameters?.externalLinkId) {
            _id = this.task.parameters?.externalLinkId
          } else {
            _id = this.task.parameters?.linkId
          }
        }
        if (this.task.link?.id == this.task.id) {
          this.task.isOldLink = true
        }
        break
      default:
        break
    }

    const fav = _.find(this.userPreferences.favorites, {
      id: _id
    })

    if (fav) {
      this.task.isFavorite = true
      // if(this.verbose) { console.log('%c[ task ]', 'color: lime', this.task) }
    } else {
      this.task.isFavorite = false
      // if(this.verbose) { console.log('%c[ task ]', 'color: red', this.task) }
    }

    //Now Watch for new Favorites
    this.userPreferences.favorites$.subscribe((favorites) => {
      favorites.subscribe((favoritesArr) => {
        let _id: any
        switch (this.task.type?.toLocaleLowerCase()) {
          case 'course':
            _id = this.task.parameters?.courseId
            break
          case 'roadmap':
            _id = this.task.parameters?.roadmapId
            break
          case 'resource':
            _id = this.task.parameters?.resourceId
            break
          case 'library':
            _id = this.task.parameters?.libraryId
            break
          case 'quiz':
            _id = this.task.parameters?.quizId
            break
          case 'external link':
            if (this.task.parameters?.linkId) {
              _id = this.task.parameters?.linkId
            } else {
              if (this.task.parameters?.externalLinkId) {
                _id = this.task.parameters?.externalLinkId
              } else {
                _id = this.task.parameters?.linkId
              }
            }
            if (this.task.link?.id == this.task.id) {
              this.task.isOldLink = true
            }
            break
          default:
            break
        }

        const fav = _.find(this.userPreferences.favorites, {
          id: _id
        })

        if (fav) {
          this.task.isFavorite = true
          // if(this.verbose) { console.log('%c[ task ]', 'color: lime', this.task) }
        } else {
          this.task.isFavorite = false
          // if(this.verbose) { console.log('%c[ task ]', 'color: red', this.task) }
        }
      })
    })

    // ------------------------------------------------------------------------
    //! THIS IS WHERE WE GET SELF COMPLETION PROGRESS OF SIMPLE TYPES
    //! AND STORE IT TO THE TASKS FOR EASY READING
    //! PULLS FROM FIRESTORE selfcompletionprogress
    //! COMPLEX TYPES ALREADY HAVE THEM IN THE TASK DATA BUT SIMPLES ARE SEPARATE
    // ------------------------------------------------------------------------

    const { user } = this.localStorage.get('delphireUser')
    const app = getApp()
    const firestore = getFirestore(app)
    const progressCollection = collectionGroup(firestore, 'progress')
    let itemId = null

    if (this.task.type) {
      switch (this.task.type.toLowerCase()) {
        case 'none':
          itemId = this.task.id
          break
        case 'course':
          itemId = this.task.parameters ? this.task.parameters.courseId : null
          break
        case 'external link':
          itemId = this.task.parameters
            ? this.task.parameters.externalLinkId
            : null
          break
        case 'library':
          itemId = this.task.parameters ? this.task.parameters.libraryId : null
          break
      }

      console.log('%c[ ---- ]', 'color: aqua')
      console.log('%c[ this.task.type ]', 'color: aqua', this.task.type)
      console.log('%c[ itemId ]', 'color: aqua', itemId)

      if (itemId) {
        const q = query(
          progressCollection,
          where('userId', '==', user.id),
          where('payload.itemId', '==', itemId),
          where('userDataType', '==', 'selfcompletionprogress')
        )

        // console.log('%c------------ user.id', 'color: red', user.id)
        // console.log('%c------------ itemId', 'color: red', itemId)

        getDocs(q).then((doc) => {
          // console.log('%c[ doc.empty ]', 'color: aqua', doc.empty)
          if (!doc.empty) {
            let existingUserData = doc.docs[0].data()
            // console.log('%c[ existingUserData ]', 'color: deeppink', existingUserData)
            // console.log('%c[ task ]', 'color: deeppink', this.task)

            this.task.selfCompleted = existingUserData.payload.selfCompleted
            this.task.selfCompletedReason =
              existingUserData.payload.selfCompletedReason
          }
        })
      }
    }

    // ------------------------------------------------------------------------
    //! THIS IS WHERE WE GET REAL PROGRESS OF NORMAL TYPES
    // ------------------------------------------------------------------------

    if (!this.trackProgress(this.task) &&  this.task.type !== 'Course') {
      return
    }
    if (this.task.type === 'External Link') {
      this.progressService
        .getFirestoreAnalyticsData(this.task)
        .then((value) => {
          this.task = value
        })
    } 
    else if(this.task.type === 'Course') {
      if(this.task.course){
        const courseId = this.task.course.id
        const userId = this.localStorage.get('delphireUser').user.id
        let tenantId = this.localStorage.get('currentSpace').id
        if(Capacitor.isNativePlatform()) {
          tenantId = this.localStorage.get('currentSpace').id
        }
        this.delphireApi.postRequestPrisma('/iam/registrations',{tenantId,courseId: courseId, learnerId:userId}).then((res)=>{
          res.subscribe((data)=>{
            if(data.body.message){
              this.delphireApi.postRequestPrisma('/iam/registrations',{tenantId: environment.globalScormTenantId ,courseId: courseId, learnerId:userId}).then((res)=>{
                res.subscribe((data)=>{
                  this.task.started = data.body.registrations[0].firstAccessDate ? true : false
                  this.task.percentage = Math.round(data.body.registrations[0].registrationCompletionAmount * 100)
                  this.task.completed = data.body.registrations[0].registrationCompletion === 'COMPLETED' ? true : false || this.task.percentage === 100
                //   this.longPollService.longPoll$(data.body.registrations[0].id).subscribe((data: any)=>{
                //     console.log('Long Polling', data)
                //     this.task.started = data.firstAccessDate ? true : false
                //     this.task.percentage = Math.round(data.registrationCompletionAmount * 100)
                //     this.task.completed = data.registrationCompletion === 'COMPLETED' ? true : false || this.task.percentage === 100
                //   }
                // )
                }
              )})
            }else{
            this.task.started = data.body.registrations[0]?.firstAccessDate ? true : false
            this.task.percentage = Math.round(data.body.registrations[0]?.registrationCompletionAmount * 100)
            this.task.completed = data.body.registrations[0]?.registrationCompletion === 'COMPLETED' ? true : false || this.task.percentage === 100
          //   this.longPollService.longPoll$(data.body.registrations[0].id).subscribe((data: any)=>{
          //     console.log('Long Polling', data)
          //     this.task.started = data.firstAccessDate ? true : false
          //     this.task.percentage = Math.round(data.registrationCompletionAmount * 100)
          //     this.task.completed = data.registrationCompletion === 'COMPLETED' ? true : false || this.task.percentage === 100
          //   }
          // )
            }
            }
          )
      })
    }
        
    }
    else {
      this.progressService
        .getFirestoreProgressData(this.task)
        .subscribe((value) => {
          this.task = value
        })
    }
  }
}

@Component({
  selector: 'self-complete-dialog',
  templateUrl: './self-complete-dialog.html'
})
export class SelfCompleteDialog {
  reason: string = ''
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
